import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { LoginModule } from './areas/login/login.module';
import {
  AccessControlModule,
  SharedLibModule,
  SearchableMultiSelectSharedDataService,
  SearchableMultiSelectComponent,
} from '@nimbus/shared-lib';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuth } from '@okta/okta-auth-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import appConfig from '../app.config';
import { DocumentationModule } from './areas/documentation/documentation.module';
import {
  AngularMaterialModule,
  AppShellComponent,
  StartupSplashScreenService,
} from 'core-global-frontend-common-ui';
import { InjectorService } from './shared/services/injector.service';
import { PushPipe } from '@ngrx/component';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ImpersonationModule } from 'libs/global/frontend/legacy/shared-lib/src/lib/features/impersonation/impersonation.module';
import {
  AuthenticationComponent,
  AuthenticationRoutingModule,
} from 'core-global-frontend-security';
import { DashboardModule } from './areas/dashboard/dashboard.module';
import { ActionsWrapperComponent } from './shared/formly/wrappers/actions-wrapper/actions-wrapper.component';
import { PanelWrapperComponent } from './shared/formly/wrappers/panel-wrapper/panel-wrapper.component';
import { WarningWrapperComponent } from './shared/formly/wrappers/warning-wrapper/warning-wrapper.component';
import { BuysideInputComponent } from './shared/formly/types/buyside-input/buyside-input.component';
import { CheckboxTypeComponent } from './shared/formly/types/checkbox/checkbox-type.component';
import { GeoComponent } from './shared/formly/types/localization/geo/geo.component';
import { InfoButtonInputTemplateComponent } from './shared/formly/types/info-button-input/info-button-input-template.component';
import { LanguageComponent } from './shared/formly/types/localization/language/language.component';
import { PartnerSelectComponent } from './shared/formly/types/partner-select/partner-select.component';
import { TabsTypeComponent } from './shared/formly/types/tabs/tabs-type.component';
import { ToggleComponent } from './shared/formly/types/toggle/toggle.component';
import { WarningComponent } from './shared/formly/types/warning/warning.component';
import { AllowedCharactersValidator } from './shared/formly/validators/allowed-characters.validator';
import { DomainNameValidator } from './shared/formly/validators/domain-name.validator';
import { DomainTermsValidator } from './shared/formly/validators/domain-terms.validator';
import { DomainUniqueValidator } from './shared/formly/validators/domain-unique.validator';
import { DomainLimitValidator } from './shared/formly/validators/domain-limit.validator';
import { KeywordsUniqueValidator } from './shared/formly/validators/keywords-unique.validator';
import { DomainTldValidator } from './shared/formly/validators/domain-tld.validator';
import { KeywordsValidator } from './shared/formly/validators/keywords.validator';
import { SubdomainNameValidator } from './shared/formly/validators/subdomain-name.validator';
import { MinCharacterLengthValidator } from './shared/formly/validators/min-character-length.validator';
import { allowedCharactersValidationMessage } from './shared/formly/error-message/allowed-characters-validation-message';
import { MaxCharacterLengthValidator } from './shared/formly/validators/max-character-length.validator';
import { maxlengthValidationMessage } from './shared/formly/error-message/max-length-validation-message';
import { minlengthValidationMessage } from './shared/formly/error-message/min-length-validation-message';
import { RampjsModule } from './areas/rampjs/rampjs.module';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { DomainCreatorSingleFormComponent } from './areas/domain/types/domain-creator-single-form/domain-creator-single-form.component';
import { DomainModule } from './areas/domain/domain.module';
import { PixelManagerModule } from './areas/pixel-manager/pixel-manager.module';
import { ExternalToolsModule } from './areas/external-tools/external-tools.module';
import { MatchCharacterLengthValidator } from './shared/formly/validators/match-character-length.validator';
import { matchLengthValidationMessage } from './shared/formly/error-message/match-length-validation-message';
import { NotificationRouteModule } from './areas/notification/notification.module';
import { DomainCreatorServiceFactory } from './shared/services/domain-creator-cache-factory.service';
import { FullGeoCacheService } from './shared/services/full-geo-cache.service';
import { FullLanguageCacheService } from './shared/services/full-language-cache-service';
import { PartnerCacheService } from './shared/services/partner-cache.service';
import { SectionWrapperComponent } from './shared/formly/wrappers/section-wrapper/section-wrapper.component';

const oktaAuth = new OktaAuth(appConfig.oidc);
const moduleConfig: OktaConfig = { oktaAuth };
const formlyConfig = {
  wrappers: [
    { name: 'actions-wrapper', component: ActionsWrapperComponent },
    { name: 'panel-wrapper', component: PanelWrapperComponent },
    { name: 'warning-wrapper', component: WarningWrapperComponent },
    { name: 'section-wrapper', component: SectionWrapperComponent },
  ],
  types: [
    { name: 'buyside-input', component: BuysideInputComponent },
    { name: 'checkbox-type', component: CheckboxTypeComponent },
    {
      name: 'domain-creator-repeat',
      component: DomainCreatorSingleFormComponent,
    },
    { name: 'geo', component: GeoComponent },
    { name: 'input-with-tooltip', component: InfoButtonInputTemplateComponent },
    { name: 'language', component: LanguageComponent },
    { name: 'partner-select', component: PartnerSelectComponent },
    {
      name: 'searchable-multi-select',
      component: SearchableMultiSelectComponent,
    },
    { name: 'tabs', component: TabsTypeComponent },
    { name: 'toggle-type', component: ToggleComponent },
    { name: 'warning', component: WarningComponent },
  ],
  validators: [
    {
      name: 'allowed-characters-validator',
      validation: AllowedCharactersValidator,
    },
    { name: 'domain-name-validator', validation: DomainNameValidator },
    { name: 'domain-tld-validator', validation: DomainTldValidator },
    { name: 'domain-name-terms-validator', validation: DomainTermsValidator },
    { name: 'domain-unique-validator', validation: DomainUniqueValidator },
    { name: 'domain-limit-validator', validation: DomainLimitValidator },
    { name: 'keywords-unique-validator', validation: KeywordsUniqueValidator },
    { name: 'keywords-validator', validation: KeywordsValidator },
    { name: 'match-character-length-validator', validation: MatchCharacterLengthValidator},
    { name: 'maxLength', validation: MaxCharacterLengthValidator },
    {
      name: 'min-character-validation',
      validation: MinCharacterLengthValidator,
    },
    { name: 'subdomain-name-validator', validation: SubdomainNameValidator },
  ],
  validationMessages: [
    {
      name: 'allowed-characters-validator',
      message: allowedCharactersValidationMessage,
    },
    {
      name: 'domain-name-validator',
      message:
        'Invalid domain: only alphanumeric and single dashes are allowed, subdomains are not allowed, and the domain must be less than 64 characters, including the dot and TLD',
    },
    {
      name: 'domain-tld-validator',
      message:
        'Only certain Top Level Domains are available',
    },
    {
      name: 'domain-name-terms-validator',
      message: 'Domain name contains an invalid term',
    },
    { name: 'domain-unique-validator', message: 'Domain names must be unique' },
    { name: 'domain-limit-validator', message: 'You have exceeded your domain limit. Please remove some to continue.' },
    {
      name: 'keywords-unique-validator',
      message: 'Duplicate keywords are not allowed',
    },
    {
      name: 'keywords-validator',
      message: 'At least 10 distinct keywords are required',
    },

    { name: 'match-character-length-validator', message: matchLengthValidationMessage},
    { name: 'maxLength', message: maxlengthValidationMessage },
    { name: 'min-character-validation', message: minlengthValidationMessage },
    { name: 'required', message: 'This field is required' },
    {
      name: 'subdomain-name-validator',
      message:
        "Subdomain should not contain '-' or space. Replace any of those characters with '_' characters.",
    },
  ],
};

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        SharedLibModule.forRoot(),
        StoreModule.forRoot({}),
        EffectsModule.forRoot({}),
        FormlyModule.forRoot(formlyConfig),
        StoreDevtoolsModule.instrument({
            name: 'Partner',
            logOnly: environment.name === 'prod' || environment.name.startsWith('uat'),
        }),
        AppRoutingModule,
        DocumentationModule,
        NotificationRouteModule,
        OktaAuthModule.forRoot(moduleConfig),
        LoginModule,
        AngularMaterialModule,
        ImpersonationModule,
        BrowserAnimationsModule,
        AccessControlModule,
        CommonModule,
        DomainModule,
        RampjsModule,
        PixelManagerModule,
        AppShellComponent,
        PushPipe,
        AuthenticationComponent,
        AuthenticationRoutingModule,
        DashboardModule,
        ExternalToolsModule], providers: [
        SearchableMultiSelectSharedDataService,
        MatSnackBar,
        GoogleAnalyticsService,
        StartupSplashScreenService,
        provideHttpClient(withInterceptorsFromDi()),
        {
          provide: FullGeoCacheService,
          useFactory: (factory: DomainCreatorServiceFactory) => factory.getDomainCacheServices().geoService,
          deps: [DomainCreatorServiceFactory],
        },
        {
          provide: FullLanguageCacheService,
          useFactory: (factory: DomainCreatorServiceFactory) => factory.getDomainCacheServices().languageService,
          deps: [DomainCreatorServiceFactory],
        },
        {
          provide: PartnerCacheService,
          useFactory: (factory: DomainCreatorServiceFactory) => factory.getDomainCacheServices().partnerService,
          deps: [DomainCreatorServiceFactory],
        },
    ] })
export class AppModule {
  constructor(_injector: Injector) {
    InjectorService.injector = _injector;
  }
}
